<div class="flex justify-center items-start search-bar-wrapper gap-2">
  <div class="w-full">
    <div class="flex search-bar-section gap-3 w-full h-full">
      <span class="items-center pi pi-search text-2xl search-icon"></span>
      <input
        class="search-bar h-full"
        type="text"
        placeholder="Search Product"
        [(ngModel)]="searchValue"
        (ngModelChange)="setAutoCompleteValue()"
        (keydown.enter)="setSearchValue()"
        (blur)="noSearch()"
        (focus)="showSearchHistoryItems()"
      />
      @if (searchValue) {
        <span
          class="items-center pi pi-times delete-icon mr-4"
          (click)="deleteSearchValue()"
        ></span>
      }
    </div>
    @if (showSearchHistory && searchHistory.length > 0) {
      <div class="w-full search-history-content flex flex-col">
        <div class="search-history-title w-full mt-2">Search history</div>
        @for (item of searchHistory; track item; let i = $index) {
        <div class="search-item-section flex flex-row items-center justify-between w-full">
          <i class="pi pi-history"></i>
          <div class="search-item cursor-pointer" (click)="setSearchValueFromHistory(item)">{{ item }}</div>
          <span 
            class="material-symbols-rounded" 
            (click)="delOneSearchHistory(i)"
            style="font-size: 17px; color: #282E38;"
          >
            close
          </span>
        </div>
        }
      </div>
    }
    @if (showAutoComplete && autoComplete && autoComplete.length > 0) {
      <div class="w-full search-content gap-4">
        <div class="w-full gap-4">
          @for (item of autoComplete; track item; let i = $index) {
          <div class="search-item-section flex flex-row items-center justify-between w-full">
            <!-- <div (click)="setSearchValueFromHistory(item)">{{item}}</div> -->
            <div class="autoComplete-item" (click)="setSearchValueFromHistory(item)"
              [innerHTML]="replaceSearchPartToBold(searchValue, item)"></div>
            <i class="pi pi-external-link" style="color: #9e9e9e"></i>
          </div>
          }
        </div>
        <amm-search-recommand class="w-full flex flex-col"></amm-search-recommand>
      </div>
    }
  </div>

  @if (searchProductStore.showPanelBtn()) {
    @if (searchProductStore.showPanel()) {
      <button class="filter-btn" (click)="showHideFilter()" [style]="{ width: '185px','min-width': '150px' }">
        Hide Filter & Sort
      </button>
    } @else {
      <button class="filter-btn" (click)="showHideFilter()" [style]="{ width: '170px', 'min-width': '150px' }">
        <span class="material-symbols-rounded" style="font-size: 17px; color: #000000;">tune</span>
        <div>Filter & Sort</div>
      </button>
    }

  }
</div>