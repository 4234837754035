import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { MAX_AUTOCOMPLETE_ITEMS_ON_SEARCH } from '../../constants';
import {
  ProductFilterService,
  ProductService,
  StorageService,
} from '../../services';
import { SearchProductStore } from '../../stores';
import { SearchRecommandComponent } from '../search-recommand/search-recommand.component';

@Component({
  selector: 'amm-global-search',
  standalone: true,
  imports: [
    FormsModule,
    OverlayPanelModule,
    DividerModule,
    ButtonModule,
    SearchRecommandComponent,
  ],
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.scss',
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  //public searchValue: string = '';
  get searchValue() {
    return this.searchProductStore.searchKeyWord();
  }
  set searchValue(term: string) {
    this.searchProductStore.setSearchKey(term);
  }
  public showSearchHistory: boolean = false;
  public showAutoComplete: boolean = false;
  public autoComplete?: Array<string>;
  public searchHistory: Array<string> = [];

  public storageService: StorageService = inject(StorageService);
  public productService: ProductService = inject(ProductService);
  public searchProductStore = inject(SearchProductStore);
  private productFilterService = inject(ProductFilterService);
  private destory$: Subject<boolean> = new Subject<boolean>();
  private sanitizer = inject(DomSanitizer);
  private router = inject(Router);
  ngOnInit(): void {
    this.searchHistory =
      this.storageService.getLocalItem('searchHistory') || [];
  }
  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }
  showHideFilter(): void {
    this.searchProductStore.toggleFilterPanel();
  }
  setSearchValue(): void {
    if (this.searchValue) {
      this.searchProductStore.showFilterPanelBtn();
    } else {
      this.searchProductStore.hideFilterPanelBtn();
    }
    this.showSearchHistory = false;
    this.showAutoComplete = false;
    if (this.searchValue) {
      this.searchHistory.push(this.searchValue);
      if (this.searchHistory.length > 8) {
        this.searchHistory = this.searchHistory.slice(
          0,
          this.searchHistory.length - 8,
        );
      }
      this.storageService.setLocalItem('searchHistory', this.searchHistory);
    }
    this.productFilterService.searchKey$.next(this.searchValue);
    this.router.navigate(['home', { outlets: { mainBody: ['search-result'] } }]);
  }

  setAutoCompleteValue(): void {
    this.showAutoComplete = true;
    this.showSearchHistory = false;
    if (this.searchValue.length > 1) {
      this.productService
        .getAutoComplete(this.searchValue)
        .subscribe((autoC) => {
          this.autoComplete = autoC.splice(0, MAX_AUTOCOMPLETE_ITEMS_ON_SEARCH);
        });
    }
  }

  showSearchHistoryItems(): void {
    this.showSearchHistory = true;
  }
  delOneSearchHistory(i: number): void {
    this.searchHistory.splice(i, 1);
    this.storageService.setLocalItem('searchHistory', this.searchHistory);
  }
  noSearch(): void {
    setTimeout(() => {
      this.showSearchHistory = false;
      this.showAutoComplete = false;
    }, 500);
  }
  deleteSearchValue(): void {
    this.searchValue = '';
    this.searchProductStore.hideFilterPanelBtn();
    this.searchProductStore.hideFilterPanel();
    this.productFilterService.clearSearchFilter$.next(true);
    this.productFilterService.searchKey$.next(this.searchValue);
    this.router.navigate(['home', { outlets: { mainBody: null } }]);
  }
  setSearchValueFromHistory(item: string): void {
    this.searchValue = item;
    this.searchProductStore.showFilterPanelBtn();
    this.showSearchHistory = false;
    this.showAutoComplete = false;
    this.productFilterService.searchKey$.next(this.searchValue);
    this.router.navigate(['home', { outlets: { mainBody: ['search-result'] } }]);
  }

  replaceSearchPartToBold(userInput: string, replaceString: string): any {
    let regex = new RegExp(userInput, 'gi');
    let result = replaceString.replace(regex, function (match) {
      return `<span style="color:#282E38">${match}</span>`;
    });

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }
}
