<div class="flex justify-between global-nav-wrapper">
  <div class="flex items-center gap-6 logo-container">
    <a class="cursor-pointer" (click)="onExplore()">
      <img
        class="brand-logo"
        src="../../../../assets/img/logo/logo_complete_horizontal.png"
        alt="logo"
        aria-hidden="true"
      />
    </a>
    <button
      [class.primary-button-active]="shouldExploreBtnActiveStyle"
      type="button"
      (click)="onExplore()"
    >
      Explore
    </button>
  </div>
  <!-- Global Search  -->
  <amm-global-search class="w-full flex justify-center"></amm-global-search>

  <!-- Function Section -->
  <div class="flex items-center gap-8 p-2 function-container">
    <div class="flex">
      <amm-button-with-bage
        [iconName]="iconName.favorite"
        iconDesciption="favorites"
        [isBtnActive]="isFavoritePage"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
      <amm-button-with-bage
        [iconName]="iconName.orders"
        iconDesciption="orders"
        [isBtnActive]="isOrdersPage"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
      <amm-button-with-bage
        [iconName]="iconName.shoppingCart"
        iconDesciption="carts"
        [isBtnActive]="isShoppingCartPage"
        [counter]="shopInfoStore.shoppingCartCount()"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
    </div>

    <!-- Avatar Section/Login Section -->
    @if (authService.isLoggedIn()) {
    <!-- TODO: This need to be finalized with UI design team -->
    <div
      class="flex flex-row items-center gap-1 profile-img-warpper cursor-pointer relative"
      (click)="op.toggle($event)"
    >
      @if(messageStore.hasUnreadMsgs()) {
      <div class="badge-container">!</div>
      } @if (googleLoginService.isLoggedIn() &&
      storageService.hasLocalItem('avatar')) {
      <img #panelHolder class="avatar-img" [src]="avatar" alt="" />
      } @else {
      <div #panelHolder class="avatar-img login-account">
        {{ loginAccount.nickName?.charAt(0)?.toUpperCase() }}
      </div>
      }
      <span class="material-symbols-outlined">keyboard_arrow_down</span>

      <p-overlayPanel #op>
        <div class="account-panel flex flex-col">
          <div class="info flex flex-col">
            @if (googleLoginService.isLoggedIn() &&
            storageService.hasLocalItem('avatar')) {
            <img #panelHolder class="avatar-img" [src]="avatar" alt="" />
            } @else {
            <div #panelHolder class="avatar-img login-account">
              {{ loginAccount.nickName?.charAt(0)?.toUpperCase() }}
            </div>
            }

            <div class="flex flex-col flex-wrap">
              <div class="panel-text">Welcome back,</div>
              <div class="panel-text panel-username">
                {{ loginAccount.nickName }}
              </div>
            </div>
          </div>

          <div class="actions flex flex-col">
            <ng-container *ngFor="let slice of dropdownItemArrays">
              <div
                *ngFor="let action of slice"
                class="action flex items-center cursor-pointer"
                (click)="onButtonClick(action.leftIconName)"
                (click)="op.hide()"
              >
                <span
                  class="material-symbols-outlined"
                  style="font-size: 17px"
                  >{{ action.leftIconName }}</span
                >
                <div class="action-label">{{ action.label }}</div>
              </div>
              <hr class="divider" *ngIf="slice.length > 1" />
            </ng-container>
          </div>
        </div>
      </p-overlayPanel>
    </div>
    } @else {
    <button class="button-action-primary" type="button" (click)="onLogin()">
      Log in
    </button>
    }
  </div>
</div>
